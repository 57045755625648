import { $TSFixMe } from '@upsolve/shared/dist/types';
import { orderBy, sum } from 'lodash';
import reviewsJSON from "../../../content/onBuild/reviewData.json";
import filterReviewWithKeywords from './filterReviewWithKeywords';

export default function getFilteredReviewsData(
  pictureNodes: $TSFixMe /* see ReviewsListingQuery for example */,
): {
  rating: string;
  reviewCount: number;
  reviews: Array<$TSFixMe /* see ReviewTile props */>
} {
  const filteredReviews = orderBy(reviewsJSON, ["reviewAt"], ["desc"])
    .filter((r) => (r.reviewText || "").length > 5 && r.sentiment === "positive" && !filterReviewWithKeywords(r))
    .map((r, ri) => ({
      ...r,
      reviewerImage: r.type === "facebook" ? pictureNodes[ri % 12] : undefined,
    }))
  return {
    rating: (sum(reviewsJSON.map((r) => r.rating)) / reviewsJSON.filter((r) => r.rating != null).length).toFixed(2),
    reviewCount: reviewsJSON.length,
    reviews: orderBy(filteredReviews, ["reviewAt"], ["desc"]),
  };
}